// Styles written by YOURNAME @ YOURCOMPANY

/* ------------------------------------------------------------------------ *\
 * Tribe Events Notices
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern, selector-max-id, selector-no-qualifying-type, selector-max-type */

/* Tribe is stupidly specific with their classes... */

.tribe-events-notices {
    & {
        background: #9F0000;
        border: 1px solid darken(#9F0000, 10);
        border-radius: 5px;
        margin: 0 0 20px;
        padding: 10px 20px;
    }

    ul {
        color: #FFFFFF;
        font-display: swap;
        font-family: "Lato";
        font-size: 14px;
        font-weight: 400;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    ul li {
        color: #FFFFFF;
    }
}
