/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Normalize
# Typography
# Elements
# Forms
# Navigation
	## Links
	## Menus
# Accessibility
# Alignments
# Clearings
# Widgets
# Content
	## Posts and pages
	## Comments
# Infinite scroll
# Media
	## Captions
	## Galleries
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
html {-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%; min-height: 100%; position: relative; }

body {margin: 0; padding-bottom: 469px;}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {display: block;}

audio, canvas, progress, video {display: inline-block;vertical-align: baseline;}

audio:not([controls]) {display: none;height: 0;}

[hidden],template {display: none;}

a {background-color: transparent; color: #ff0000; }

a:focus, a:active, a:hover {outline: 0 !important;}

abbr[title] {border-bottom: 1px dotted;}

b, strong {font-weight: bold;}

dfn {font-style: italic;}

mark {background: #ff0;color: #000;}

small {font-size: 80%;}

sub, sup {font-size: 75%;line-height: 0;position: relative;vertical-align: baseline;}

sup {top: -0.5em;}

sub {bottom: -0.25em;}

img {border: 0;}

svg:not(:root) {overflow: hidden;}

figure {margin: 1em 40px;}

hr {box-sizing: content-box;height: 0;}

pre {overflow: auto;}

code, kbd, pre, samp {font-family: monospace, monospace;font-size: 1em;}

button,input,optgroup,select,textarea {color: inherit;font: inherit;margin: 0;}

button {overflow: visible;}

button,select {text-transform: none;}

button,html input[type="button"],input[type="reset"],input[type="submit"] {-webkit-appearance: button;cursor: pointer;}

button[disabled],html input[disabled] {cursor: default;}

button::-moz-focus-inner,input::-moz-focus-inner {border: 0;padding: 0;}

input {line-height: normal;}

input[type="checkbox"],input[type="radio"] {box-sizing: border-box;padding: 0;}

input[type="number"]::-webkit-inner-spin-button,input[type="number"]::-webkit-outer-spin-button {height: auto;}

input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration {-webkit-appearance: none;}

fieldset {border: 1px solid #c0c0c0;margin: 0 2px;padding: 0.35em 0.625em 0.75em;}

legend {border: 0;padding: 0;}

textarea {overflow: auto;}

optgroup {font-weight: bold;}

table {border-collapse: collapse;border-spacing: 0;}

td,th {padding: 0;}

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
body, button, input,select,optgroup,textarea {color: #666;font-family: 'Lato', sans-serif;font-size: 16px;line-height: normal;}

h1,h2,h3,h4,h5,h6 {clear: both; margin: 0 0 15px; color: #00529c; font-family: 'adobe-garamond-pro'; font-weight: 700;}

h1 { font-size: 44.16px; letter-spacing: 0.3px; line-height: 1.2em; text-transform: uppercase; }

h3 { font-size: 28px; letter-spacing: 0.3px; line-height: 1.2em; }

h4 { font-size: 20px; font-weight: bold;}

p {margin-bottom: 1.5em;}

dfn,cite,em,i {font-style: italic;}

blockquote {margin: 0 0.5em;}

address {margin: 0 0 1.5em;}

pre {background: #eee; font-family: "Courier 10 Pitch", Courier, monospace; font-size: 15px; line-height: 1.6;margin-bottom: 1.6em;max-width: 100%;overflow: auto;padding: 1.6em;}

code,kbd,tt,var {font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;font-size: 15px;font-size: 0.9375rem;}

abbr,acronym {border-bottom: 1px dotted #666;cursor: help;}

mark,ins {background: #fff9c0;text-decoration: none;}

big {font-size: 125%;}

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
html {box-sizing: border-box;}

*,*:before,*:after {box-sizing: border-box;-webkit-box-sizing: border-box;-ms-box-sizing: border-box;}

body {background: #fff; /* Fallback for when there is no custom background color defined. */}

blockquote:before,blockquote:after,q:before,q:after {content: "";}

blockquote,q {quotes: "" "";}

hr {background-color: #ccc;border: 0;height: 1px;margin-bottom: 1.5em;}

ul,ol {margin: 0 0 1.5em 0; padding-left: 20px;}

ul {list-style: disc;}

ol {list-style: decimal;}

li > ul,li > ol {margin-bottom: 0; margin-left: 1.5em;}

li {color: #141414; font-size: 16px; letter-spacing: 0.3px; line-height: 1.62em; }

dt {font-weight: bold;}

dd {margin: 0 1.5em 1.5em;}

img {height: auto; /* Make sure images are scaled correctly. */max-width: 100%; /* Adhere to container width. */}

figure {margin: 1em 0; /* Extra wide images within figure tags don't overflow the content area. */}

table {margin: 0 0 1.5em;width: 100%;}

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {border: 1px solid;border-color: #ccc #ccc #bbb;border-radius: 3px;background: #e6e6e6;color: rgba(0, 0, 0, .8);font-size: 16px; line-height: 1;padding: .6em 1em .4em;}

button:hover,input[type="button"]:hover,input[type="reset"]:hover,input[type="submit"]:hover {border-color: #ccc #bbb #aaa;}

button:focus,input[type="button"]:focus,input[type="reset"]:focus,input[type="submit"]:focus,button:active,input[type="button"]:active,input[type="reset"]:active,input[type="submit"]:active {border-color: #aaa #bbb #bbb;}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {color: #666;border: 1px solid #ccc;border-radius: 3px;padding: 3px;}

select {border: 1px solid #ccc;}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {color: #111;}

textarea {width: 100%;}

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
a {color: #ff0000;}

a:visited {color: #ff0000;}

a:hover,a:focus,a:active {color: #ff0000;}

a:focus {outline: thin dotted;}

a:hover,a:active {outline: 0;}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {display: inline;float: left;margin-right: 1.5em;}
.alignright {display: inline;float: right;margin-left: 1.5em;}
.aligncenter {clear: both;display: block;margin-left: auto;margin-right: auto;}
embed,iframe,object {max-width: 100%;}
/* -------------------------- */
/*       Header Seaction      */
/* -------------------------- */

.container-fluid {max-width: 1544px; padding-left: 20px; padding-right: 20px;}
.navbar {min-height: inherit; margin-bottom: 0; border: none; background: #FFF; }
.navbar-right {margin-right: 0; }
.social {float: left; padding-left: 5px; position: absolute; right: 0; }
.social ul > li { float: left; line-height: 38px; list-style: outside none none; margin: 0 1px 0 24px; }
.social ul {margin: 0; padding: 0;}
.social ul > li > a {font-size: 30px; padding: 0;}
.social ul > li > a .fa-facebook-official {color: #4867aa; }
.social ul > li > a .fa-twitter {color: #1da1f2; }
.social ul > li > a:hover .fa-facebook-official, .social ul > li > a:hover .fa-twitter {opacity: 0.7; }
.navbar-brand {height: auto; padding-top: 27px; padding-bottom: 35px; }
.search-box {width: 470px; position: relative; float: left; }
.navbar-form {margin: 45px 53px 42px 0;  padding-right: 175px; position: relative;}
.search-box .form-control {border: 1px solid #8a8a8a;border-radius: 50px; box-shadow: none !important;color: #b1b1b1;font-size: 14.64px;height: 42px;letter-spacing: 0.3px;line-height: 40px;padding: 0 17px; font-family: 'Lato', sans-serif; font-weight: normal; width: 100%; -moz-appearance:none; -webkit-appearance:none; -ms-appearance:none;}
.btn-search {background-color: rgba(0, 0, 0, 0); border: medium none; color: #00529c; font-size: 14.64px; padding: 0; position: absolute; right: 18px; top: 12px; width: 20px; text-align: right; }
.navbar-bg {background-color: #00529c; }
.navbar-nav {width: 100%; }
.navbar-bg .container-fluid {max-width: 1543px; padding: 0 10px;}
.navbar-nav li {border-right: 1px solid rgba(225, 225, 225, 0.3); float: left; text-align: center; width: 16.6666%; height: 149px; display: table; position: relative; }
.navbar-nav li:last-child {border-right: 0; }
.nav > li > a > img {height: 68px;}
.navbar-nav li a {padding: 20px 5px 17px; display: table-cell; vertical-align: middle; text-decoration: none; background:transparent ; }
.menu-item a img {padding-right: 0 !important; height: 68px;}
.navbar-nav li a span {display: block; }
.navbar-nav li a:hover, .navbar-nav li a:focus, .navbar-nav li a:active, .navbar-nav li a.active {background-color: #1761A3; }
.navbar-inverse .navbar-toggle {position: absolute; bottom: 18px; right: 20px; border: none; padding: 0; background-color: transparent !important; margin: 0; z-index: 1;  border-radius: 0;}

.navbar-nav li.current-menu-parent > a, .navbar-nav li.current_page_item > a, .navbar-nav li.current-menu-parent > a, .navbar-nav li.current-menu-ancestor > a  {background-color: #1761A3;}

.navbar-toggle .icon-bar {height: 4px; border-radius: 0; width: 27px;}
.navbar-nav li a span {
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    display: flex;
    display:-webkit-flex;
    color: #ffffff;
    font-size: 14px;
    height: 40px;
    line-height: 1.1em;
    margin-top: 4px;
    text-transform: uppercase;
    vertical-align: middle;
    width: 100%;
    letter-spacing: 0.3px;
}
.menu {margin: 0; padding: 0; }

/* -------------------------- */
/*       Banner Seaction      */
/* -------------------------- */
.banner {margin-top: 17px; position: relative; margin-bottom: 22px; }
.bx-wrapper { -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; border: 0px; left: 0; margin-bottom: 0; }
ul.bxslider li { background-position: center; background-repeat: no-repeat; background-size: cover; }
.bx-wrapper img { width: 100%; display: block; opacity: 0; }
.banner-outer-img img {height: auto; width: 100%; opacity: 0; }
.banner-outer-img { background-position: center bottom; background-size: cover; background-repeat: no-repeat; }
.banner-content-outer {position: absolute; bottom: 0; left: 0; right: 0; }
.banner-content-inner {overflow: hidden;text-align: center; border: solid #FFF; background-color: #ed1c2f; border-width: 14px 15px; padding: 21px 32px 28px; border-bottom: none; }
.banner-content-outer > .container-fluid {max-width: 1466px;}
.about-title, .banner-content-inner h2 {color: #ffffff; font-family: 'adobe-garamond-pro'; font-size: 38.67px; font-weight: 700; line-height: 1.2em; margin: 0; }
.scroll-down {position: absolute; bottom: -64px; left: 0; right: 0; text-align: center; }
.scroll-down a {background-color: #00529c; border: 12px solid #ffffff; border-radius: 100%; display: inline-block; height: 80px; line-height: 52px; text-align: center; width: 80px; }

/* -------------------------- */
/*       About  Seaction      */
/* -------------------------- */

.about { background-color: #00529c; padding: 86px 0 79px; text-align: center; margin-bottom: 23px; }
.about-inner {margin: 0 auto; max-width: 1010px;}
.about-title {font-size: 30px; margin-bottom: 25px;}
.about-inner p {color: #ffffff; font-family: "Lato",sans-serif; letter-spacing: 0.3px; line-height: 1.62em; margin-bottom: 41px; }
.btn-default {background-color: #ed1c2f; border-color: #ed1c2f; border-radius: 100px; color: #ffffff !important; font-family: 'adobe-garamond-pro'; font-size: 22px; font-weight: 700; letter-spacing: 0.3px; padding: 12px 31px 15px; text-transform: uppercase; }
.btn-default:focus, .btn-default:active, .btn-default.active, .btn-default:hover { background-color: #ff404f !important; border-color:#ff404f !important; color: #FFF!important; box-shadow: none; }
.site-content { margin-bottom: 20px; }

/* -------------------------- */
/*         News Seaction      */
/* -------------------------- */


.news {background-color: #ed1c2f; padding: 76px 0 56px; overflow-x: hidden; }
.news > .container-fluid > .row {margin: 0 -28px}
.news > .container-fluid > .row > div {padding: 0 28px}
.news-post {background-color: #FFF; margin-bottom: 30px; float: left; width: 100%;}
.news-post-img img {width: 100%;}
.news-post-content {padding: 31px 35px; text-align: center; min-height: 230px; }
.news-post h2, .news-post h2 a {color: #005099; font-family: 'adobe-garamond-pro'; font-size: 30px; margin: 0 0 15px; text-transform: uppercase; font-weight: 700; }
.news-post h2 {margin-bottom: 21px; }
.news-post p {letter-spacing: 0.3px; margin-bottom: 0; color: #141414; }
.no-bg {background: transparent; }
.twitter-post {height: 50%; padding-bottom: 13px; overflow: auto;}
.facebook-post {height: 50%; padding-top: 13px; overflow: auto; text-align: center; }
.twitter-post-bg {background-color: #FFF; height: 100%; overflow: auto;}
.facebook-post-bg {background-color: #FFF; height: 100%; overflow: auto; }


/* -------------------------- */
/*       Footer Seaction      */
/* -------------------------- */

footer .container-fluid {max-width: 1530px; }
.footer-top {background-color: #383838; padding: 66px 0 47px; margin-bottom: 20px; min-height: 316px;}
.foot-box h2 {color: #FFF; font-family: 'adobe-garamond-pro'; font-size: 30px; font-weight: 700; margin: 0 0 14px; text-transform: uppercase; letter-spacing: 0.3px; padding-bottom: 15px; }
.foot-box .btn-default {font-size: 18px; padding: 10px 55px 11px;}
.foot-box p { color: #ffffff; font-size: 20px; line-height: 1.6em; margin-bottom: 29px; letter-spacing: 0.3px; }
.foot-box p span {display: block; padding-left: 31px; position: relative; margin-bottom: 7px;}
.foot-box p span a {color: #FFF; }
.foot-box p span i {left: 0;margin-top: -1px;position: absolute;top: 4px; }
.footer-bottom {background-color: #00529c; padding: 39px 0 32px; min-height: 123px;}
 .foot-menu {margin-left: -11px; padding-top: 2px; }
.copy-right img {float: left; margin-right: 16px; margin-top: -2px; width: 63px; }
.copy-right p { color: #ffffff; letter-spacing: 0.3px; line-height: 1.5em; margin-bottom: 0; }
.copy-right p > br {display: none;}
.copy-right a { color: #ffffff; }
.copy-right a:focus, .copy-right a:hover { color: #ffffff; text-decoration: underline; }
.foot-menu ul {margin: 0; padding: 0; list-style: none; }
.foot-menu ul li {display: inline-block; margin-right: 7px; padding-right: 9px; margin-bottom: 4px; position: relative; line-height: 1.21em;}
.foot-menu ul li:before {background-color: #ffffff; content: ""; height: 15px; position: absolute; right: -1px; top: 4px; width: 1px;}
.foot-menu ul li a {color: #ffffff; display: inline-block; letter-spacing: 0.3px; }
.foot-menu ul li:nth-last-child(2):before, .foot-menu ul li:last-child:before {display: none; }

/*  */

.page-id-10 .main-content { padding: 31px 0 172px; }
.main-content { padding: 31px 0 50px; }
.main-content ul li, .main-content ol li {padding-left: 5px;}
.leftbar-section {float: left;padding: 0 20px;width: 70%;}
.sidebar-section {float: right;padding: 0 20px;width: 27.1%;}
.inner-banner .banner-outer-img {background-color: #e81c2e; padding: 30px 0 30px; }
.inner-banner h1 {color: #ffffff; font-family: 'adobe-garamond-pro'; font-size: 44.16px; font-weight: 700; letter-spacing: 0.3px; line-height: 1.2em; margin: 0; text-transform: uppercase; }
.main-content h2 {color: #00529c; font-family: 'adobe-garamond-pro'; font-size: 35px; font-weight: 700; letter-spacing: 0.3px; line-height: 1.2em; text-transform: uppercase; margin: 0 0 28px; }
.main-content p {color: #141414; font-size: 16px; letter-spacing: 0.3px; line-height: 1.62em; margin-bottom: 20px;}
.main-content .button { background-color: #e81c2e; color: #ffffff; display: inline-block; font-size: 1.2em; margin: 15px 0; padding: 15px; transition: background-color .15s }
.main-content .button:hover, .main-content .button:focus, .main-content .button:active { background-color: darken(#e81c2e, 10%); text-decoration: none; }
.main-content .left-bar { overflow-x: auto; }
.main-content .left-bar .row { margin-left: 0; margin-right: 0; }
.main-content table { width: 100%; }
.main-content table td { border: 1px solid #00529c; }
.main-content table td, .main-content table th { padding: 8px; }
.main-content table th, .main-content table thead td, .main-content table thead th { background-color: #00529c; color: #ffffff; }
.main-content table th:not(:last-of-type), .main-content table thead td:not(:last-of-type), .main-content table thead th:not(:last-of-type) { border-right: 1px solid #ffffff; }
.main-content table th:last-of-type, .main-content table thead td:last-of-type, .main-content table thead th:last-of-type { border-right: 1px solid #00529c; }
.mamber-img { background-color: #0e5ca1; border-radius: 100%; display: inline-block; height: 150px; width: 150px; padding: 1px; margin-bottom: 21px; }
.team-mamber {float: left; padding: 0 20px; text-align: center; width: 25%; min-height: 310px; }
.team-mamber h3 {color: #000000; font-size: 20px; font-weight: bold; margin: 0 0 4px; font-family: 'Lato', sans-serif;}
.team-mamber span { color: #000000; display: block; font-size: 16px; font-style: italic; letter-spacing: 0.3px; margin-bottom: 5px; }
.team-mamber a {color: #eb0e1e; font-size: 14px; letter-spacing: 0.3px; }
.team {padding-top: 23px;}
.links-box {background-color: #efefef; float: right; margin: 10px 0 20px; padding: 29px 31px 60px; width: 100%; }
.links-box h3 {color: #00529c; font-size: 20px; font-weight: bold; margin: 25px 0 9px; text-transform: uppercase;font-family: 'Lato', sans-serif; }
.links-box h3 a {color: #00529c; }
.links-box ul {list-style: outside none none; margin: 0; padding: 0; margin-bottom: 33px; }
.links-box ul:last-child {margin-bottom: 0; }
.links-box ul li {display: block; margin-bottom: 8px; }
.links-box ul li a {color: #ff0000; font-weight: 500; padding-left: 9px; transition: all 0.2s; position: relative; }
.links-box ul li a:before {transition: all 0.2s; background-color: #ff0000; content: ""; display: inline-block; height: 1px; left: 0; margin-top: 1px; position: absolute; top: 50%; width: 6px; }
.links-box ul li a:hover:before {width: 15px;}
.links-box ul li a:hover {padding-left: 20px;}
.action-box { background-image: url("../media/background-action-box.jpg"); float: right; margin: 0 0; overflow: hidden; padding: 38px 35px 24px; position: relative; width: 100%; width: 100%; z-index: 0; }
.action-box p { color: #ffffff; line-height: 1.24em; }
.action-box h2 { color: #ffffff; font-size: 30px; font-weight: bold; margin-bottom: 19px; }
.action-box:before {background-color: #00529c; bottom: 0; content: ""; left: 0; opacity: 0.81; position: absolute; right: 0; top: 0; z-index: -1; }
.simple-content { padding-bottom: 50px;}
.sub-menu { background: #00529c; transition: all 0.3s; left: 0; margin: 0; padding: 0; position: absolute; top: 105%; width: 300px; z-index: 1; visibility: hidden; opacity: 0; display: none; }
.sub-menu .sub-menu { display: block; opacity: 1; position: static; left: auto; top: auto; visibility: visible; }
.sub-menu .sub-menu a { text-indent: 20px; }
.sub-menu .sub-menu li a span { font-size: 12px; }
.sub-menu .sub-menu .sub-menu a { text-indent: 40px; }
.navbar-nav li:hover > .sub-menu, .navbar-nav li.open > .sub-menu {visibility: visible; opacity: 1; top: 100%; display: block; }
.navbar-nav li.open > .sub-menu li.open > .sub-menu {top: 0; }

.sub-menu li { border-right: medium none; display: block; height: auto; width: 100%; position: relative; }
.sub-menu li:last-child {border: none;}
.sub-menu li a {color: #ffffff; text-decoration: none; display: block; font-size: 14px; padding: 9px 20px; text-align: left; text-transform: uppercase; }
.sub-menu li a span {height: auto; margin: 0; line-height: 1.5em; display: block; }

.sub-menu .sub-menu li:before {border-right: 2px solid #ffffff; border-top: 2px solid #ffffff; content: ""; height: 8px; position: absolute; left: 22px; top: 14px; transform: rotate(45deg); -webkit-transform: rotate(45deg); width: 8px; z-index: 1; }
.sub-menu .sub-menu .sub-menu li:before { left: 42px; }
.sub-menu li > .submenu { display: block; position: static; left: auto; top: auto; margin-left: 20px; }
footer { position: absolute; bottom: 0; left: 0; right: 0; margin-top: 10px; }
.leftbox { float: left; width: 69%; padding: 0 20px; }
.rightbox { float: left; width: 31%; padding: 0 20px; }
.foot-box address { padding-left: 4px; padding-top: 5px; }
.foot-box address p { line-height: 1.3em;}

.navbar-toggle span {transition: all 0.3s;}
.navbar-toggle.open .icon-bar:nth-child(1) { transform: rotate(45deg) translateX(6px) translateY(5px); -webkit-transform: rotate(45deg) translateX(6px) translateY(5px);}
.navbar-toggle.open .icon-bar:nth-child(2) { opacity: 0; }
.navbar-toggle.open .icon-bar:nth-child(3) { transform: rotate(-45deg) translateX(6px) translateY(-5px); -webkit-transform: rotate(-45deg) translateX(6px) translateY(-5px); }


.overlay {background: #000000;cursor: pointer;bottom: 0;left: 0; position: absolute;right: 0;top: 0;z-index: 1;visibility: hidden;opacity: 0; display: none; }

.open-menu .overlay {visibility: visible; opacity: 0.6; display: block; }





.news-post-img a {display: block;height: 313px;overflow: hidden;}

.news-post-img img {height: 100%; object-fit: cover; }

.hidden-lg { display: none; }
.visible-lg { display: inline-block; }

.noblock { display: inline !important; width: auto !important; }

.social .button--header,
.social--mobile .button--header {
    & {
        color: #FFFFFF;
        background: #00529C;
        padding: 8px 12px;
        display: inline-block;
        margin-top: 3px;
        transition: background-color 0.15s;
    }

    &:hover,
    &:focus,
    &:active {
        color: #FFFFFF;
        background: #1761A3;
        text-decoration: none;
    }
}

.social {
    display: inline;
    margin-right: 15px;
    white-space: nowrap;
}

.social ul li:last-of-type {
    margin-right: 16px;
}

.about-inner ul, .about-inner ol {text-align: left; }
.about-inner ul li, .about-inner ol li {color: #FFF; }

.bx-controls {display: none; }
@media only screen and (max-width: 1024px) {
    .twitter-post-bg {
        -webkit-overflow-scrolling: touch !important;
        overflow: scroll !important;
    }
  .twitter-post-bg > iframe {
      /*height: 100% !important;*/
  }

}

.social--mobile {
    display: none;
}

.button__callout {
    & {
        width: 100%;
        max-width: 346px;
        height: 100%;
        max-height: 245px;
        display: block;
        margin: 0 auto;
        position: relative;
        margin-bottom: 16px;
    }

    &::after {
        content: "";
        display: block;
        padding-bottom: (245/346) * 100%;
    }

    .button__callout__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
    }

    .button__callout__link {
        position: absolute;
        width: calc(100% - 32px);
        left: 50%;
        transform: translateX(-50%);
        bottom: 16px;
        background: #ED1C2E;
        padding: 16px 8px;
        color: #FFFFFF;
        font-weight: 400;
        font-size: 16px;
        transition: background-color 0.15s;
        text-align: center;
        z-index: 1;
    }

    .button__callout__link:hover {
        text-decoration: none;
        background: darken(#ED1C2E, 5%);
    }
}

.article--post-excerpt {
    & {
        margin-bottom: 20px;
    }

    &:not(:last-of-type) {
        border-bottom: 2px solid (#ED1C2E);
    }

    .image {
        display: block;
        margin: 0 auto 20px;
        max-width: 100%;
        width: auto;
    }

    .img-placeholder {
        display: block;
        width: 300px;
        max-width: 100%;
        aspect-ratio: 300/225;
        background-color: #efefef;
        margin: 0 auto 20px;
    }
}
