// Styles written by YOURNAME @ YOURCOMPANY

/* ------------------------------------------------------------------------ *\
 * Menu List
\* ------------------------------------------------------------------------ */

/* ============================ GLOBAL VARIANTS =========================== */

.menu-list_container {
    &::after {
        clear: both;
        content: "";
        display: block;
    }
}

.menu-list {
    & {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
    }

    &::after {
        clear: both;
        content: "";
        display: block;
    }
}

.menu-list_item {
    & {
        float: left;
        position: relative;
    }

    &.-parent {
        display: flex;
        flex-wrap: wrap;
    }

    &.-mega {
        position: static;
    }
}

.menu-list_link {
    & {
        display: block;
        flex: 1 1 0%;
        min-width: 0;
        text-decoration: none;
    }
}

.menu-list_toggle {
    & {
        background: none;
        border: 0;
        cursor: pointer;
        display: block;
        flex: 0 0 auto;
        font-size: 1em;
        margin: 0;
    }

    > .icon {
        transition: transform 0.15s;
    }

    .menu-list_item.is-active > & > .icon {
        transform: rotate(-180deg);
    }
}

/* between variant */

.menu-list.-between {
    & {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    > .menu-list_item {
        flex: 0 0 auto;
        float: none;
    }

    &::after {
        flex: 0 0 100%;
    }
}

/* center variant */

.menu-list.-center {
    & {
        float: right;
        position: relative;
        right: 50%;
    }

    > .menu-list_item {
        left: 50%;
    }
}

/* flex variant */

.menu-list.-flex {
    & {
        display: flex;
    }

    > .menu-list_item {
        flex: 1 1 0%;
        min-width: 0;
    }
}

/* vertical variant */

.menu-list.-vertical {
    & {
        text-align: left;
    }

    > .menu-list_item {
        float: none;
    }
}

/* vertical reverse variant */

.menu-list.-vertical.-reverse {
    & {
        text-align: right;
    }
}

/* tier variant */

.menu-list[class*="-tier"] {
    & {
        min-width: 100%;
    }
}

/* tier accordion variant */

.menu-list[class*="-tier"].-accordion {
    & {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.25s, visibility 0s 0.25s;
        visibility: hidden;
        width: 100%;
    }

    .menu-list_item.-parent.is-active > & {
        max-height: 500px;
        transition: max-height 0.25s;
        visibility: visible;
    }
}

/* tier overlay variant */

.menu-list[class*="-tier"].-overlay {
    & {
        left: -999em;
        opacity: 0;
        position: absolute;
        top: -999em;
        transition: left 0s 0.15s, opacitwy 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
        width: 250px;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        opacity: 1;
        transition: opacity 0.15s;
        visibility: visible;
    }
}

/* tier variant */

.menu-list[class*="-tier"].-overlay.-reverse {
    & {
        transition: opacity 0.15s, right 0s 0.15s, top 0s 0.15s;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 0;
        transition: opacity 0.15s;
    }
}

/* tier1 overlay variant */

.menu-list.-tier1.-overlay {
    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: 0;
        top: 100%;
    }

    > .menu-list_item.is-active > .menu-list_toggle > .icon {
        transform: rotate(-90deg);
    }
}

/* tier1 overlay reverse variant */

.menu-list.-tier1.-overlay.-reverse {
    & {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 0;
    }

    > .menu-list_item.is-active > .menu-list_toggle > .icon {
        transform: rotate(90deg);
    }
}

/* tier2 overlay variant */

.menu-list.-tier2.-overlay {
    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: 100%;
        top: 0;
    }
}

/* tier2 overlay reverse variant */

.menu-list.-tier2.-overlay.-reverse {
    & {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 100%;
    }
}

/* ============================ CUSTOM VARIANTS =========================== */

/* mega variant */

.menu-list_container.-mega {
    & {
        display: flex;
        left: -999em;
        opacity: 0;
        position: absolute;
        right: 999em;
        top: -999em;
        transition: left 0s 0.15s, opacity 0.15s, right 0s 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
    }

    .menu-list_item.-mega.-parent:hover > &,
    .menu-list_item.-mega.-parent.is-active > & {
        left: 0;
        opacity: 1;
        right: 0;
        top: 100%;
        transition: opacity 0.15s;
        visibility: visible;
    }

    .menu-list.-tier1 {
        flex: 1 1 0%;
        min-width: 0;
    }

    .menu-list.-tier2 {
        flex: 0 0 100%;
    }
}

/* pagination variant */

.menu-list.-pagination {
    & {
        font-display: swap;
        font-family: "Lato";
        font-size: 14px;
        font-weight: 400;
    }

    .menu-list_item:not(:last-child) {
        margin-right: 8px;
    }

    .menu-list_link.-current {
        color: #111111;
        cursor: auto;
    }
}
