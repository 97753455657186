@media screen and (max-width: 1450px) {

    .menu-item a img {height: 50px; }
    .navbar-nav li a span {font-size: 13px;}
    .banner-content-inner h2 {font-size: 30px; }
    .sidebar-section {width: 30%;}
    .search-box {width: 300px;}

}


@media screen and (max-width: 1366px) {
    .news {padding: 56px 0 28px;}
    .foot-box h2, .news-post h2, .news-post h2 a {font-size: 20px;}
    .foot-box p, .news-post p {font-size: 16px;}
    .facebook-post ._2p3a {width: 100% !important;}



}
@media screen and (max-width: 1258px) {
    body {padding-bottom: 0;}
    footer {position: relative !important; }

    .hidden-lg { display: inline-block; }
    .visible-lg { display: none; }
}
@media screen and (min-width: 1258px) {
    .copy-right p > br {display: inline-block;}
}
@media screen and (min-width: 768px) and (max-width: 1450px) {
    .navbar-collapse.collapse, .navbar-bg .container-fluid {padding: 0;}

}
@media screen and (max-width: 1170px) {

    .navbar-header, .navbar-form {width: 45%;}

    .navbar-form {margin: 30px 50px 30px 0; border: none; }

    .navbar-brand {padding-top: 27px; padding-bottom: 26px; }

    .navbar-brand {max-width: 400px;}

    .search-box {width: 100%;}

    .banner-content-inner h2 {font-size: 20px; }

    .news > .container-fluid > .row {margin: 0 -15px}

    .news > .container-fluid > .row > div {padding: 0 15px}

    .main-content h2 {font-size: 25px; margin-bottom: 15px; }

    .main-content p {font-size: 14px; }

    .team-mamber h3 {font-size: 16px;}
    .team-mamber span {font-size: 13px;}
    .inner-banner h1 {font-size: 30px;}
    .inner-banner .banner-outer-img {padding: 25px 0;}
    .team-mamber {width: 50%; margin-bottom: 33px; }
    .leftbar-section {width: 65%;}
    .sidebar-section {width: 35%;}
    .navbar-nav li a span br {display: none; }



}



@media screen and (min-width: 768px) {


    .facebook-post, .twitter-post {height: 290px !important;}


}




@media screen and (max-width: 991px) {

    .menu-item a img {height: 30px;}
    .navbar-nav li a span {height: 60px;}

    .navbar-nav li a span {font-size: 12px;}

    .navbar-brand {padding-top: 28px; padding-bottom: 28px; }

    .navbar-brand > img {max-width: 350px;  }

    .facebook-post, .twitter-post {width: 50%; float: left; padding: 0; }

    .twitter-post {padding-right: 15px;}

    .facebook-post {padding-left: 15px;}
    .leftbar-section {width: 100%;}
    .sidebar-section {width: 100%;}
    .news {padding: 30px 0 0px;}
    .copy-right p > br {display: inline-block;}
    .foot-box h2 {font-size: 20px;}
    .foot-box p {font-size: 15px;}
    .leftbox {width: 60%;}
    .rightbox {width: 40%;}
    .banner-content-inner h2 br {display: none;}

    .banner-content-inner { border-width: 10px; padding: 15px 15px 18px; }
    .banner-content-inner h2 { font-size: 18px; }
    .banner { margin: 15px 0; }
    .foot-box address { padding-top: 0; }

    .sub-menu li a span { height: auto; }

}


@media screen and (max-width: 767px) {
    li {font-size: 12px;}
    h1 {font-size: 22px;}
    h2 {font-size: 18px;}
    h3 {font-size: 16px;}
    h4 {font-size: 15px;}
    h5 {font-size: 13px;}
    h6 {font-size: 12px;}

    body {padding-bottom: 0;}
    footer {position: relative; }

    .navbar-brand {padding: 19px 20px 15px;}

    .navbar-bg .container-fluid {padding: 0 20px;}

    .navbar-header, .navbar-form {width: auto;}

    .navbar-brand > img {max-width: 100%;  }

    .navbar-form {overflow: hidden; margin:  0 -20px ; background-color: #025ca6; padding: 13px 67px 13px 20px; }

    .navbar-bg {padding: 0; }

    .navbar-nav { width: auto; margin-top: 0; margin-bottom: 0; }

    .navbar-nav li {width: 100%; border-right: none; height: auto; }

    .navbar-nav li a {padding: 10px 20px; }

    .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {background-color: #00529c; }

    .search-box .form-control {height: 31px; line-height: 30px; }

    .btn-search {font-size: 14px; right: 14px; top: 8px;}

    .search-box {width: 100%; }

    .social {display: none; }

    .banner-content-outer {position: relative; }

    .banner-content-inner h2 {font-size: 18px; }

    .facebook-post, .twitter-post {width: 100%; padding: 0 0 18px; height: 320px; overflow: auto; }

    .banner {margin: 8px 0; }

    .banner-content-outer > div {padding: 0; }

    .banner-content-inner {border-left: 0; border-right: 0; border-top-width: 8px; padding: 19px 10px 25px;}

    .scroll-down {display: none; }

    .about {padding: 48px 0 65px; margin-bottom: 9px; }

    .about-title {font-size: 17.3px; margin-bottom: 19px; }

    .about-inner p { font-size: 13px; line-height: normal; margin-bottom: 35px; }

    .btn-default { font-size: 16px; padding: 10px 35px;}
    .button { display: block; width: 100%; margin-bottom: 15px; }

    .about .btn-default {display: block; }

    .news {padding: 18px 0 5px; /*5 */}

    .news-post {margin-bottom: 18px; }

    .no-bg {margin-bottom: 0; }

    .site-content {margin-bottom: 8px;}

    .news-post h2, .news-post h2 a {font-size: 17px; }

    .news-post h2 {margin-bottom: 12px;}

    .news-post p {font-size: 13px;}

    .news-post-content {padding:21px 18px 28px; min-height: inherit; }

    .news-post p {font-size: 13px; }

    .foot-box h2 {font-size: 17.5px;  }

    .foot-box {margin-bottom: 36px;}

    .foot-box p {font-size: 13px; line-height: normal; margin-bottom: 23px; }

    .foot-box .btn-default {display: block; font-size: 13px; padding: 8px 35px; }

    .footer-top {padding-bottom: 8px; padding-top: 31px; margin-bottom: 10px; }

    .foot-box p span {padding-left: 20px; line-height: 1.2em; margin-top: 14px;}
    .footer-bottom {padding: 23px 0 24px }
    /*.foot-box p span i {top: 0; }*/
    .copy-right {text-align: center; }
    .copy-right img {float: none; margin: 0 auto 15px;width: 47px;}
    .copy-right p {font-size: 12px; }

    .page-id-10 .main-content {padding: 19px 0 14px;}
    .main-content {padding: 19px 0 14px;}
    .main-content h2 {font-size: 17.5px; margin-bottom: 17px;}
    .main-content p {font-size: 12px; }
    .inner-banner h1 {font-size: 22px; }
    .inner-banner .banner-outer-img {padding: 14px 0 15px;}
    .team {padding-top: 33px; padding-bottom: 32px; }
    .mamber-img { height: 114px; width: 114px;}
    .mamber-img {margin-bottom: 14px;}
    .team-mamber h3 {font-size: 15px;margin-bottom: 3px;}
    .team-mamber span {font-size: 12px;line-height: 10px;margin-bottom: 0;}
    .team-mamber a {font-size: 10px;}
    .links-box {padding: 17px 23px 50px;}
    .links-box ul {margin-bottom: 0; }
    .links-box ul li {margin-bottom: 1px;}
    .links-box ul li a {font-size: 12px; padding-left: 7px; }
    .links-box h3 {margin: 24px 0 2px; font-size: 15px;}
    .action-box {padding: 27px 25px 18px; }
    .action-box h2 {font-size: 22px; margin-bottom: 15px;}
    .leftbar-section {width: 100%;}
    .sidebar-section {width: 100%;}
    .leftbox {width: 100%;}
    .rightbox {width: 100%;}

    .navbar-collapse {bottom: 0;overflow-y: auto;position: fixed;right: 0;top: 0;transform: translateX(270px);visibility: hidden;width: 270px; box-shadow: 10px 0 20px rgba(0, 0, 0, 0.1) inset; height: auto !important; display: block !important; transition: all 0.5s;}
    .site {transition: all 0.3s;}
    .menu {padding: 0; margin: 0;}
    .menu-item a img {display: none;}
    .open-menu.site {transform: translateX(-270px);}
    .open-menu .navbar-collapse {visibility: visible; display: block; }
    .navbar-nav li, .navbar-nav li a {display: block; height: auto; text-align: left; overflow: hidden; }
    .sub-menu li > .sub-menu, .sub-menu {position: relative; left: 0 !important; top: 0 !important; display: block; opacity: 1; width: 100%; background-color: #1761a3; float: none; box-shadow: none !important;}
    .sub-menu {display: block; visibility: visible; }

    .sub-menu .sub-menu a { text-indent: 10px;}
    .sub-menu .sub-menu .sub-menu a { text-indent: 20px; }
    .sub-menu .sub-menu li:before { top: 17.5px; }
    .sub-menu .sub-menu .sub-menu li:before { top: 17.5px; }

    .navbar-nav li a span{  display: inline-block; vertical-align: middle; height: auto; justify-content: left;  margin: 0; overflow: hidden; width: 150px; }
    .team-mamber {min-height: inherit;}


    .foot-box address {padding-top: 0;}
    ul.bxslider li { background: transparent !important; }
    ul.bxslider li img { opacity: 1; }

    .news-post-img a { height: auto; }
     .bx-viewport {background-color: #ed1c2f;}
    .bx-viewport, ul.bxslider li { height: auto !important; }


    .social--mobile {
        & {
            display: block;
        }

        li {
            display: inline;
            list-style: none;
            margin-left: 0;
            margin-right: 8px;
        }

        li:last-of-type {
            margin-right: 0;
        }

        li a {
            font-size: 30px;
        }

        a {
            color: #FFFFFF;
        }
    }

    .social--mobile .button--header {
        & {
            color: #FFFFFF;
            background: #ed1c2f;
            padding: 10px 35px;
            font-size: 16px;
            border-radius: 100px;

        }

        &:hover,
        &:focus,
        &:active {
            color: #FFFFFF;
            background: lighten(#ed1c2f, 10%);
        }
    }

     .menu-image-title-after.menu-image-title {
         padding-left: 0;
         width: 100%;
         font-size: 14px;
     }

     .sub-menu {
         margin-left: 16px;
     }

     .sub-menu li a {
         font-size: 13px;
     }

     .sub-menu .sub-menu li {
         margin-left: 4px;
     }

    .sub-menu .sub-menu li a {
         margin-top: 2px;
         font-size: 12px;
         text-indent: 0;
         padding-left: 14px;
     }

     .sub-menu .sub-menu li:before {
         left: 0;
     }

}

@media screen and (max-width: 479px) {
    .team-mamber {width: 100%;}
    .foot-bottom-inner .copy-right p br {display: none; }
}

@media screen and (max-width: 320px) {
    .facebook-post, .twitter-post {height: 172px;}
    .foot-bottom-inner .copy-right p br {display: inline-block; }
}
