// Styles written by YOURNAME @ YOURCOMPANY

/* ------------------------------------------------------------------------ *\
 * Helpers
\* ------------------------------------------------------------------------ */

._visuallyhidden {
    & {
        left: -999em !important;
        position: absolute !important;
        top: -999em !important;
    }
}


// when screen is < 768
@media screen and (max-width: 767px) {
    ._tablet:not(._phone),
    ._notebook:not(._phone),
    ._desktop:not(._phone) {
        display: none !important;
    }
}

// when screen is >= 768 and < 1024
@media screen and (min-width: 768px) and (max-width: 959px) {
    ._phone:not(._tablet),
    ._notebook:not(._tablet),
    ._desktop:not(._tablet) {
        display: none !important;
    }
}

// when screen is >= 1024 and < 1280
@media screen and (min-width: 960px) and (max-width: 1023px) {
    ._phone:not(._notebook),
    ._tablet:not(._notebook),
    ._desktop:not(._notebook) {
        display: none !important;
    }
}

// when screen is >= 1280
@media screen and (min-width: 1024px) {
    ._phone:not(._desktop),
    ._tablet:not(._desktop),
    ._notebook:not(._desktop) {
        display: none !important;
    }
}
