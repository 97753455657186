// Styles written by YOURNAME @ YOURCOMPANY

/* ------------------------------------------------------------------------ *\
 * Tribe Events General
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern, selector-max-id, selector-no-qualifying-type, selector-max-type */

/* Tribe is stupidly specific with their classes... */

/* Loader */

.tribe-events-ajax-loading {
    & {
        display: none;
    }
}

/* Content */

#tribe-events-content {
    & {
        margin: 0;
        padding: 0;
    }
}

/* Footer */

#tribe-events-footer {
    & {
        margin-bottom: 0;
    }
}
